
.ui.menu.Top-level-menu {
    border-radius: 0;
    margin-bottom: 2em;
}

.ui.card.civ-card.Uncommon {
    box-shadow: 2px 2px 3px 3px #437b41;
}

.ui.card.civ-card.Rare {
    box-shadow: 2px 2px 3px 3px #6bc9d9;
}

.ui.card.civ-card.Epic {
    box-shadow: 2px 2px 3px 3px #bc529f;
}

/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/
